<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <g>
                <ellipse class="st8" cx="125" cy="217.7" rx="124.5" ry="10.2"/>
                <g>
                    <path class="st9" d="M204.7,90.5l-18.1-18.1c-1.9-1.9-5.1-1.9-7,0c-0.9,0.9-1.5,2.2-1.5,3.5c0,1.3,0.5,2.6,1.5,3.5l9.7,9.7h-53.7     c-2.7,0-5,2.2-5,5c0,2.7,2.2,5,5,5h53.7l-9.7,9.7c-1.9,1.9-1.9,5.1,0,7c0.9,0.9,2.2,1.5,3.5,1.5c1.3,0,2.6-0.5,3.5-1.5l18.1-18.1     c0.2-0.2,0.5-0.5,0.6-0.8c0.1-0.1,0.1-0.2,0.2-0.4l0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.2-0.4,0.2-0.6     c0-0.1,0.1-0.2,0.1-0.3C206.4,93.4,205.9,91.7,204.7,90.5z"/>
                    <g>
                        <path class="st4-1" d="M148.7,178.2H59c-8.3,0-15.1-6.8-15.1-15.1V37.3c0-8.3,6.8-15.1,15.1-15.1h89.8c8.4,0,15.1,6.8,15.1,15.1      v28c0,2.9-2.3,5.2-5.2,5.2s-5.2-2.3-5.2-5.2v-28c0-2.6-2.1-4.8-4.8-4.8H59c-2.6,0-4.8,2.1-4.8,4.8v125.8c0,2.6,2.1,4.8,4.8,4.8      h89.8c2.6,0,4.8-2.1,4.8-4.8v-39.6c0-2.9,2.3-5.2,5.2-5.2s5.2,2.3,5.2,5.2v39.6C163.9,171.4,157.1,178.2,148.7,178.2z"/>
                    </g>
                    <path class="st9" d="M97.9,191.9L54.9,178c-6.6-2.1-11.1-8.3-11.1-15.2V36.5c0-7.9,7.7-13.6,15.3-11.2L98,37.5l9.5,3.3     c5.2,1.8,8.7,6.7,8.7,12.3V179C116.2,188.5,106.8,195.1,97.9,191.9z"/>
                </g>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconLogout',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
    .st0{opacity:0.3;fill:#242626;}
	.st1{fill:#BC2C35;}
	.st2{fill:none;stroke:#1F3D5B;stroke-width:2;stroke-miterlimit:10;}
	.st3{fill:none;stroke:#1F3D5B;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
	.st4-1{fill:#212E3D;}
	.st5{opacity:0.12;}
	.st6{fill:#FFFFFF;}
	.st7{fill:#A5B2B7;}
	.st8{fill:#F0F4F9;}
	.st9{fill:#4979B2;}
	.st10{fill:none;stroke:#212E3D;stroke-miterlimit:10;}
</style>
